import { lighten, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { createContext, TouchEventHandler, useCallback, useContext, VFC } from "react";
import { ReclaimEdition } from "../../reclaim-api/Users";
import { EditionBadgeBase, EditionBadgeBaseProps } from "../EditionBadgeBase";

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.typography.caption,
    backgroundColor: lighten(theme.colors.grey, 0.6),
    borderRadius: 4,
    color: theme.palette.text.primary,
    display: "inline-block",
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: "16px",
    padding: theme.spacing(0, 0.5),
    textTransform: "uppercase",
    pointerEvents: "auto",
  },
}));

export type EditionBadgeProps = Omit<EditionBadgeBaseProps, "paid" | "classes"> & {
  edition: ReclaimEdition;
  EditionBadgeBaseClasses?: EditionBadgeBaseProps["classes"];
};

export type EditionBadgeContextData = { tooltipOpen: boolean; setTooltipOpen: (open: boolean) => void };
export const EditionBadgeContext = createContext<EditionBadgeContextData>({
  tooltipOpen: false,
  setTooltipOpen: () => void 0,
});

export const EditionBadge: VFC<EditionBadgeProps> = ({ edition, className, EditionBadgeBaseClasses, ...rest }) => {
  const classes = useStyles();
  const { tooltipOpen, setTooltipOpen } = useContext(EditionBadgeContext);

  const handleTouchEndCapture = useCallback<TouchEventHandler<HTMLDivElement>>(
    (e) => {
      if (!tooltipOpen) {
        // preventDefault on touchEnd blocks click event
        e.preventDefault();
        e.stopPropagation();
        setTooltipOpen(true);
      } else setTooltipOpen(false);
    },
    [setTooltipOpen, tooltipOpen]
  );

  return (
    <EditionBadgeBase
      onTouchEndCapture={handleTouchEndCapture}
      className={clsx(classes.root, className)}
      paid={edition !== ReclaimEdition.Free}
      classes={EditionBadgeBaseClasses}
      {...rest}
    >
      {edition.label}
    </EditionBadgeBase>
  );
};
