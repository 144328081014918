import { lighten, makeStyles } from "@material-ui/core";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import clsx from "clsx";
import { FC } from "react";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      ...theme.typography.caption,
      backgroundColor: lighten(theme.colors.grey, 0.6),
      borderRadius: 4,
      color: theme.palette.text.primary,
      display: "inline-block",
      fontWeight: theme.typography.fontWeightMedium,
      lineHeight: "16px",
      padding: theme.spacing(0, 0.5),
      textTransform: "uppercase",
      pointerEvents: "auto",
    },
    paid: {
      backgroundColor: theme.colors.logo.corn,
    },
    medium: {},
    small: {},
    slim: {},
  }),
  {
    classNamePrefix: "EditionBadgeBase",
  }
);

export type EditionBadgeBaseJSSClassKey = keyof ReturnType<typeof useStyles>;

export type EditionBadgeBaseProps = Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  ""
> & {
  classes?: Partial<ClassNameMap<EditionBadgeBaseJSSClassKey>>;
  size?: "slim" | "small" | "medium";
  paid?: boolean;
};

export const EditionBadgeBase: FC<EditionBadgeBaseProps> = ({
  className,
  classes: extClasses,
  size = "medium",
  paid,
  children,
  ...rest
}) => {
  const classes = useStyles({
    classes: extClasses,
  });

  /********************/
  /*       JSX        */
  /********************/

  return (
    <div
      className={clsx(classes.root, className, {
        [classes.paid]: paid,
        [classes.medium]: size === "medium",
        [classes.small]: size === "small",
        [classes.slim]: size === "slim",
      })}
      {...rest}
    >
      {children}
    </div>
  );
};
